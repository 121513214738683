import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

import { useFetch } from "../../utils/hooks/fetch";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [notifications, setNotifications] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { get, data, destroy, loading } = useFetch(true);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    destroy(`/notifications/${id}`);
  };

  useEffect(() => {
    get(`/notifications`);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setNotifications(data.notifications);
    }
  }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    get(`/notifications`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleClick}
        sx={{ width: 40, height: 40 }}
        aria-describedby="notifications-popover"
      >
        <Badge badgeContent={notifications?.length} color="error">
          <NotificationsNoneIcon color="primary" />
        </Badge>
      </IconButton>
      <Popover
        id="notifications-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: { width: "30%" },
          },
        }}
      >
        <Box sx={{ py: 2, px: 2.5 }}>
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Vous avez {notifications?.length} messages en attentes
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        {!loading && (
          <List disablePadding>
            {notifications?.map((notification, index) => (
              <NotificationItem
                notification={notification}
                handleDelete={handleDelete}
                key={index}
              />
            ))}
          </List>
        )}
      </Popover>
    </>
  );
};

function NotificationItem({ notification, handleDelete }) {
  const navigate = useNavigate();
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
      onClick={() => navigate("/profile")}
    >
      <ListItem
        disableGutters
        secondaryAction={
          <IconButton
            aria-label="supprimer la notification"
            onClick={(e) => handleDelete(e, notification.id)}
          >
            <CloseIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
          </IconButton>
        }
      >
        <ListItemText
          primary={
            <>
              <Typography variant="subtitle2">{notification.title}</Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                {notification.description}
              </Typography>
            </>
          }
        />
      </ListItem>
    </ListItemButton>
  );
}

export default Notifications;
