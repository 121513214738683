import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { PDFDownloadLink, Image } from "@react-pdf/renderer";

import Pdf_header_bg from "../../../utils/images/pdf_bg_header.png";
import Pdf_email from "../../../utils/images/pdf_email.png";
import Pdf_phone from "../../../utils/images/pdf_phone.png";
import Pdf_deco from "../../../utils/images/pdf_deco.png";
import M1 from "../../../utils/images/avatars/m1.png";
import M2 from "../../../utils/images/avatars/m2.png";
import M3 from "../../../utils/images/avatars/m3.png";
import M4 from "../../../utils/images/avatars/m4.png";
import M5 from "../../../utils/images/avatars/m5.png";
import M6 from "../../../utils/images/avatars/m6.png";
import M7 from "../../../utils/images/avatars/m7.png";
import M8 from "../../../utils/images/avatars/m8.png";
import W1 from "../../../utils/images/avatars/w1.png";
import W2 from "../../../utils/images/avatars/w2.png";
import W3 from "../../../utils/images/avatars/w3.png";
import W4 from "../../../utils/images/avatars/w4.png";
import W5 from "../../../utils/images/avatars/w5.png";
import W6 from "../../../utils/images/avatars/w6.png";
import W7 from "../../../utils/images/avatars/w7.png";
import W8 from "../../../utils/images/avatars/w8.png";
import Default from "../../../utils/images/avatars/default.png";

const avatars = [
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_5.svg",
    value: M1,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_2.svg",
    value: M2,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_7.svg",
    value: M3,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_1.svg",
    value: M4,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_6.svg",
    value: M5,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_4.svg",
    value: M6,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_8.svg",
    value: M7,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_3.svg",
    value: M8,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_1.svg",
    value: W1,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_7.svg",
    value: W2,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_8.svg",
    value: W3,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_4.svg",
    value: W4,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_3.svg",
    value: W5,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_6.svg",
    value: W6,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_2.svg",
    value: W7,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_5.svg",
    value: W8,
  },
];

/**
 * Découpe le tableau en plusieurs petit tableau
 *
 * @array {Array} Tableau de String
 * @size {Number} Taille des parties du tableau
 * @returns {Array} - Tableau de tableau
 */
const eachSlice = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f7fbfd",
    width: "1000px",
    fontSize: "11px",
    color: "rgb(0, 83, 157)",
    padding: "20px 0",
  },
  header__bg: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  page__bg: {
    position: "absolute",
    top: "50%",
    left: "0",
    width: "100%",
  },
  title_deco: {
    position: "absolute",
    top: "-0px",
    left: "-15px",
    width: "20px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  profile_picture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  profile_picture_container: {
    width: "180px",
    height: "180px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "10px solid white",
    marginLeft: "20px",
    backgroundColor: "rgb(255, 255, 255, 0.8)",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eef9ff",
    width: "45%",
    padding: "20px",
    marginLeft: "20px",
  },
  main__job: {
    backgroundColor: "rgb(0, 83, 157)",
    padding: "5px 8px",
  },
  padding_sm: {
    padding: "5px 0",
  },
  body: {
    width: "90%",
    border: "2px solid rgb(255, 194, 13)",
    padding: "10px 20px",
    margin: "10px auto",
    backgroundColor: "#fff",
  },
  title__light: {
    backgroundColor: "#69acdf",
    color: "white",
    padding: "5px 10px",
    marginBottom: "15px",
    marginTop: "15px",
  },
  title__dark: {
    backgroundColor: "rgb(0, 83, 157)",
    color: "white",
    padding: "5px 10px",
    marginBottom: "15px",
    marginTop: "15px",
  },
  body__section: {
    display: "flex",
    flexDirection: "row",
    flexWrapm: "wrap",
  },
  chip__container: {
    marginBottom: "10px",
  },
  chip: {
    padding: "5px 10px",
    borderRadius: "10px 50% 10px 50%",
    backgroundColor: "#1976d2",
    margin: " 0 5px",
    color: "white",
  },
  chip_variant: {
    padding: "5px 10px",
    borderRadius: "10px 50% 10px 50%",
    backgroundColor: "rgb(255, 194, 13)",
    margin: " 0 5px",
    color: "black",
  },
  text: {
    padding: "5px 0",
    borderRadius: "10px 50% 10px 50%",
    margin: " 0 5px",
    color: "rgb(0, 83, 157)",
  },
});

// *** PROFILE TEST EN PHASE DE DEV ***
// const resume = {
//   firstname: "Jonh",
//   lastname: "Doe",
//   email: "jonhdoe@mail.fox",
//   phone_number: "0603709377",
//   address: "22 rue de la Boétie",
//   zip_code: 75000,
//   city: "Paris",
//   country: "France",
//   citizenship_status: "Français",
//   industries: [
//     "Btp",
//     "Gestion",
//     "Marketing",
//     "Gestion de projets",
//     "Gestion de l'entreprise",
//     "Comptabilité",
//   ],
//   jobs: [
//     "Manutentionaire",
//     "Conseiller",
//     "Chef de projet",
//     "Preparateur de service",
//     "Livreur",
//   ],
//   contract_type: ["CDD", "CDI"],
//   hook_sentences:
//     "Je suis actuellement en demande de stage pour le développement d'une application, Je suis actuellement en demande de stage pour le développement d'une application, Je suis actuellement en demande de stage pour le développement d'une application",
//   social_skills: ["Motivé", "Ponctuel", "Organisé"],
//   strengths: ["Autodidacte", "Excellent en communication"],
//   it_skills: "Word, excel Excel, Outlook, PowerPoint, Google Drive",
//   languages: "Français, Anglais, Allemand",
//   passions: "Peche, Musique, Sport",
//   availability: "Tout de suite",
//   geographical_mobility: "Rennes + 30km",
//   salary_expectations: "1800 - 2200 mensuel",
//   specifics_points: "Caces, permis poids lourd",
//   avatar:
//     "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_8.svg",
//   gender: "Homme",
//   work_experiences: [
//     { start_year: "2015", end_year: "2018", job_position: "Recruteur" },
//     { start_year: "2014", end_year: "2015", job_position: "Secrétaire" },
//     { start_year: "2012", end_year: "2014", job_position: "Livreur" },
//   ],
//   educations: [
//     { start_year: "2008", end_year: "2010", training_course: "Bac" },
//     { start_year: "2010", end_year: "2013", training_course: "Licence" },
//     { start_year: "2013", end_year: "2015", training_course: "Master" },
//   ],
// };
// const profile_picture = "";

/**
 * Composant pour afficher un titre avec deux variantes de style (light ou dark).
 *
 * @param {Boolean} [title_light=true] - Définit si le style du titre doit être clair (light) ou sombre (dark).
 * @param {React.ReactNode} children - Le contenu du titre à afficher (peut être une chaîne ou des éléments JSX).
 * @returns {JSX.Element} - Un conteneur avec un texte stylisé représentant le titre.
 */
const Title = ({ title_light = true, children }) => {
  return (
    <View style={styles.body__section}>
      {!title_light && <Image src={Pdf_deco} style={styles.title_deco} />}
      <Text
        style={[
          styles.padding_sm,
          title_light ? styles.title__light : styles.title__dark,
        ]}
      >
        {children}
      </Text>
    </View>
  );
};

/**
 * Flex-wrap ne fonctionnant pas sur react-pdf, pour ne pas dépasser le conteneur ou que tout soit
 * compacté, on coupe le tableau en plusieurs parties puis on map chaque partie dans une section
 *
 * @content {Array} Tableau de String
 * @chip {Number} Variant d'affichage des chips: 0 == Bleu, 1 == Jaune, 2 == Texte
 * @returns {JSX.Element} - Conteneur avec des sous sections de String
 */
const SlicedContent = ({ content, chip = 0 }) => {
  return (
    <>
      {eachSlice(content, 3).map((jobs_part, index) => (
        <View
          key={index}
          style={[styles.body__section, styles.chip__container]}
        >
          {jobs_part.map((job, job_index) => (
            <Text
              key={job_index}
              style={
                chip === 0
                  ? styles.chip
                  : chip === 1
                  ? styles.chip_variant
                  : styles.text
              }
            >
              {job}
            </Text>
          ))}
        </View>
      ))}
    </>
  );
};

/**
 * Affiche du contenu textuel dans un conteneur stylisé.
 *
 * @param {String} content - Chaine de caractères à afficher.
 * @returns {JSX.Element} - Conteneur qui affiche le texte fourni avec des styles spécifiques.
 */
const DisplayContent = ({ content }) => {
  return (
    <View style={[styles.body__section, styles.chip__container]}>
      <Text style={styles.text}>{content}</Text>
    </View>
  );
};

/**
 * Variant pour afficher des tableaux d'objets.
 *
 * @param {Array<Object>} array_object - Tableau d'objets, chaque objet contient les propriétés start_year, end_year, et un troisième champ non spécifié.
 * @returns {JSX.Element} - Conteneur qui affiche chaque élément de l'objet en ligne séparé par un tiret.
 */

const ContentVariant = ({ array_object }) => {
  return (
    <>
      {eachSlice(array_object, 3).map((part, index) => (
        <View key={index} style={styles.chip__container}>
          {part.map((object, obj_index) => (
            <Text key={obj_index} style={styles.text}>
              {object.start_year} - {object.end_year} -{" "}
              {object.job_position
                ? object.job_position
                : object.training_course}{" "}
              - {object.company ? object.company : object.school}{" "}
              {object.description && ` - ${object.description}`}
            </Text>
          ))}
        </View>
      ))}
    </>
  );
};

/**
 * Affiche le titre, et le body en fonction des paramètres fournis.
 *
 * @param {String} title - Titre de la section
 * @param {Boolean} [title_light=true] - Définit si le titre utilise le style léger ou sombre
 * @param {String[]|String} content - Tableau de chaînes de caractères ou une chaîne unique
 * @param {Boolean} [sliced=false] - Si `true`, le contenu est un tableau à diviser et afficher sous forme de chips
 * @param {Boolean} [display=false] - Si `true`, le contenu est une chaîne de texte à afficher directement
 * @param {Number} [chip=0] - Variante d'affichage des chips : 0 = Bleu, 1 = Jaune, 2 = Texte brut
 * @param {JSX.Element} [children] - Enfants supplémentaires à afficher dans la section
 * @returns {JSX.Element} - Conteneur avec des sous-sections pour afficher le contenu
 */
const BodySection = ({
  title,
  title_light = true,
  content,
  sliced = false,
  display = false,
  chip = 0,
  children,
}) => {
  return (
    <>
      <Title title_light={title_light}>{title}</Title>
      {sliced && <SlicedContent content={content} chip={chip} />}
      {display && <DisplayContent content={content} />}
      {children && children}
    </>
  );
};

// Create Document Component
const ResumePdf = ({ resume, profile_picture }) => (
  <Document style={{ position: "relative" }}>
    <Page size="A4" style={styles.page}>
      <Image src={Pdf_header_bg} style={styles.header__bg} />
      {/* <Image src={Pdf_bg} style={styles.page__bg} /> */}
      <View style={styles.header}>
        <View style={styles.profile_picture_container}>
          {profile_picture ? (
            <Image
              src={profile_picture}
              alt={resume.firstname}
              style={styles.profile_picture}
            />
          ) : resume.avatar ? (
            <Image
              src={avatars.find((a) => a.key === resume.avatar).value}
              alt={resume.firstname}
              style={styles.avatar}
            />
          ) : (
            <Image src={Default} alt={resume.firstname} style={styles.avatar} />
          )}
        </View>
        <View style={styles.main}>
          <Text style={[styles.padding_sm, { fontWeight: "900" }]}>
            {resume.firstname} {resume.lastname}
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={[styles.main__job, { color: "white" }]}>
              {resume.jobs[0]}
            </Text>
          </View>
          <Text style={styles.padding_sm}>
            {resume.city} ({resume.zip_code})
          </Text>
          <Text style={styles.padding_sm}>
            <Image src={Pdf_phone} alt="phone icon" /> {resume.phone_number}
          </Text>

          <Text style={styles.padding_sm}>
            <Image src={Pdf_email} alt="phone icon" /> {resume.email}
          </Text>
          <Text style={styles.padding_sm}>{resume.hook_sentences}</Text>
        </View>
      </View>
      <View style={styles.body}>
        <BodySection
          title="Secteur(s) d'activité(e)"
          title_light={true}
          content={resume.industries}
          sliced={true}
          chip={0}
        />
        <BodySection
          title="Métier(s)"
          title_light={false}
          content={resume.jobs}
          sliced={true}
          chip={2}
        />
        <BodySection
          title="Type(s) de contrat(s)"
          title_light={true}
          content={resume.contract_type}
          sliced={true}
          chip={2}
        />
        <BodySection title="Expériences professionnelles" title_light={false}>
          <ContentVariant array_object={resume.work_experiences} />
        </BodySection>
        <BodySection title="Formations" title_light={true}>
          <ContentVariant array_object={resume.educations} />
        </BodySection>
        <BodySection
          title="Savoir(s) être(s)"
          title_light={false}
          content={resume.social_skills}
          sliced={true}
          chip={1}
        />
        <BodySection
          title="Forces de caractères"
          title_light={true}
          content={resume.strengths}
          sliced={true}
          chip={1}
        />
        <BodySection
          title="Compétence(s) informatique(s)"
          title_light={false}
          content={resume.it_skills}
          display={true}
          chip={2}
        />
        <BodySection
          title="Point spécifique"
          title_light={true}
          content={resume.specifics_points}
          display={true}
          chip={2}
        />
        <BodySection
          title="Prétentions salariales"
          title_light={false}
          content={resume.salary_expectations}
          display={true}
          chip={2}
        />
        <BodySection
          title="Langue"
          title_light={true}
          content={resume.languages}
          display={true}
          chip={2}
        />
        <BodySection
          title="Ses passions"
          title_light={false}
          content={resume.passions}
          display={true}
          chip={2}
        />
        <BodySection
          title="Mobilité géographique"
          title_light={true}
          content={resume.geographical_mobility}
          display={true}
          chip={2}
        />
      </View>
    </Page>
  </Document>
);

const DownloadPdf = ({ resume, profile_picture }) => {
  return (
    <PDFDownloadLink
      document={<ResumePdf resume={resume} profile_picture={profile_picture} />}
      fileName={`CV_${resume.lastname}_${resume.firstname}.pdf`}
    >
      Télécharger
    </PDFDownloadLink>
  );
};

// *** VIEWER EN PHASE DE DEV ***
// const DownloadPdf = () => {
//   console.log(resume, profile_picture);
//   const avatar = avatars.find((a) => a.key === resume.avatar);
//   return (
//     <PDFViewer width="100%" style={{ minHeight: "100vh" }}>
//       <ResumePdf resume={resume} profile_picture={profile_picture} />
//     </PDFViewer>
//   );
// };

export default DownloadPdf;
