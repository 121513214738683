import React, { Suspense, useEffect, Fragment, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Cookies from "js-cookie";
import {
  COOKIE_CART_CANDIDATES,
  COOKIE_ID,
  COOKIE_TYPE,
} from "./config/config";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";

import {
  Login,
  ForgotPassword,
  Profile,
  Resumes,
  Resume,
  Candidate,
  NotFound,
  Register,
  RegisterUser,
  Industries,
  Offers,
  PurchaseCompleted,
  EmailConfirmed,
  ResetPassword,
} from "./pages";

import { Alerts, Loader, Layout } from "./components";

// Importation des composants spécifiques
import { useFetch } from "./utils/hooks/fetch";

import { UserContext, CartContext } from "./contexts";
import DownloadPdf from "./pages/Profile/Recruiter/resume_pdf";

const initialUserData = {
  id: Cookies.get(COOKIE_ID) || null,
  type: Cookies.get(COOKIE_TYPE) || null,
};

const App = () => {
  const [socialsLogData, setSocialsLogData] = useState({});
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState({
    id: null,
    recruiter_id: null,
    total_price: 0,
    available_credits: 0,
    credits_left: 0,
    total_price_left: 0,
    locked: false,
    payement_method_subscription: "Non défini",
    candidates: JSON.parse(Cookies.get(COOKIE_CART_CANDIDATES) || "[]"),
  });
  const [loading, setLoading] = useState(true);

  const { data, get } = useFetch(true);
  const { data: cartData, get: getCart } = useFetch(true);

  useEffect(() => {
    if (initialUserData.id && initialUserData.type) {
      get(`/${initialUserData.type}/${initialUserData.id}`);
      if (initialUserData.type === "recruiter") {
        getCart(`/cart/${initialUserData.id}`);
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (cartData) {
      setCart(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    Cookies.set(COOKIE_CART_CANDIDATES, JSON.stringify(cart.candidates));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <Alerts>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{ user, setUser }}>
          <CartContext.Provider value={{ cart, setCart }}>
            {loading ? (
              <Loader />
            ) : (
              <Router>
                <Suspense fallback={<Loader />}>
                  <Layout>
                    <Routes>
                      {user ? (
                        <Fragment>
                          <Route path="/profile" exact element={<Profile />} />
                          {user.user_type === "user" && (
                            <Route
                              path="/candidate/:id"
                              exact
                              element={<Candidate />}
                            />
                          )}
                          <Route
                            path="/profile/resume-public"
                            exact
                            element={<Resume />}
                          />
                          <Route
                            path="/profile/resume-private"
                            exact
                            element={<Resume />}
                          />
                          <Route
                            path="/view-resume/:resumeId"
                            element={<Resume />}
                          />
                          <Route
                            path="/purchase-completed"
                            element={<PurchaseCompleted />}
                          />
                          <Route path="/view_pdf" element={<DownloadPdf />} />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Route
                            path="/login"
                            exact
                            element={
                              <Login setSocialsLogData={setSocialsLogData} />
                            }
                          />
                          <Route
                            path="/signup"
                            element={
                              <Register socialsLogData={socialsLogData} />
                            }
                          />
                          <Route
                            path="/signup-taipai"
                            element={<RegisterUser />}
                          />
                          <Route
                            path="/forgot-password"
                            element={<ForgotPassword />}
                          />
                          <Route
                            path="/reset-password"
                            element={<ResetPassword />}
                          />
                          <Route
                            path="/email_confirmation"
                            element={<EmailConfirmed />}
                          />
                          <Route
                            path="/resend_email_confirmation"
                            element={<EmailConfirmed />}
                          />
                        </Fragment>
                      )}
                      <Route path="/offers" element={<Offers />} />

                      <Route path="/industries" element={<Industries />} />
                      <Route path="/resumes" element={<Resumes />} />
                      <Route path="/resumes/:id" element={<Resume />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Layout>
                </Suspense>
              </Router>
            )}
          </CartContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </Alerts>
  );
};

export default App;
